exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-appteam-js": () => import("./../../../src/pages/appteam.js" /* webpackChunkName: "component---src-pages-appteam-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-emergingtech-js": () => import("./../../../src/pages/emergingtech.js" /* webpackChunkName: "component---src-pages-emergingtech-js" */),
  "component---src-pages-experiences-js": () => import("./../../../src/pages/experiences.js" /* webpackChunkName: "component---src-pages-experiences-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mediateam-js": () => import("./../../../src/pages/mediateam.js" /* webpackChunkName: "component---src-pages-mediateam-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-webteam-js": () => import("./../../../src/pages/webteam.js" /* webpackChunkName: "component---src-pages-webteam-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */)
}

